import './compatibility.js';

/* Vue :) */
import Vue from 'vue';

/* Components */
const PhidiasVerify = () => import('@/modules/phidias-verify/components/PhidiasVerify/PhidiasVerify.vue');

export default {
    Vue: class {
        constructor(vueOptions) {
            vueOptions.provide = function () {
                return {};
            };
            return new Vue(vueOptions);
        }
    },
    mixins: {},
    api: {},
    states: {},
    components: {
        PhidiasVerify
    }
};